<div class="header-v2">
  <div class="header-logo-name">
    <div class="header-logo">
      <svg-icon name="dashboard-logo" [svgStyle]="{ fill: '#FCFCFC' }">
      </svg-icon>
    </div>
    <div class="header-name">
      <div class="name">
        <div class="title-1">
          {{ "dashboard.title1" | translate }}
        </div>
        &nbsp;
        <!-- space in title -->
        <div class="title-2">
          {{ "dashboard.title2" | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="header-menu">
    <mat-tab-group
      class="mat-tab-logo-hidden"
      #tabgroup
      ng-deep
      [selectedIndex]="selectedIndex"
      (selectedTabChange)="selectedTabChange(tabgroup, $event)"
    >
      <!-- KPI -->
      <mat-tab
        label="{{ 'dashboard.kpi' | translate }}"
        [aria-label]="rights.KPI"
        *ngIf="rights.KPI !== undefined"
      >
        <app-kpi
          (navigate)="onNavChange($event)"
          *ngIf="selectedTab === rights.KPI && !batterySelected"
        ></app-kpi>
      </mat-tab>
      <!-- MAP -->
      <mat-tab
        label="{{ 'dashboard.map' | translate }}"
        [aria-label]="rights.MAP"
        *ngIf="rights.MAP !== undefined"
      >
        <ng-template mat-tab-label>
          <div
            class="map"
            [ngStyle]="{
              color:
                mapLoading === 100
                  ? '#0F63FF'
                  : mapLoading === -1
                    ? '#e40101'
                    : 'white'
            }"
            [matTooltip]="hoverLoadingMap"
            [matTooltipDisabled]="mapLoading === undefined"
          >
            {{ "dashboard.map" | translate }}
          </div>
          <div class="lds-ring" *ngIf="mapLoading >= 0 && mapLoading < 100">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </ng-template>
        <app-map
          (navigate)="onNavChange($event)"
          *ngIf="selectedTab === rights.MAP || mapLoading !== undefined"
          [showDetailMap]="showDetailMap"
          [currentState]="currentStateMap"
        ></app-map>
      </mat-tab>
      <!-- LIST -->
      <mat-tab
        label="{{ 'dashboard.list' | translate }}"
        [aria-label]="rights.LIST"
        *ngIf="rights.LIST !== undefined"
      >
        <app-list
          (navigate)="onNavChange($event)"
          [filterDevice]="selectedDeviceIdFilter"
          [showInfo]="display"
          [infoCurrentState]="currentStateList"
          *ngIf="selectedTab === rights.LIST"
        ></app-list>
      </mat-tab>
      <!-- ANALYTICS -->
      <mat-tab
        label="{{ 'dashboard.analytics' | translate }}"
        [aria-label]="rights.ANALYTICS"
        *ngIf="isAdmin('SuperUser')"
      >
        <ng-template mat-tab-label>
          <table style="width: 100%">
            <tr>
              <td
                [matTooltip]="textAnalyticsTooltip"
                [matTooltipDisabled]="!displayTooltipAndCloseForAnalytics"
              >
                {{ "dashboard.analytics" | translate }}
              </td>
              <td *ngIf="displayTooltipAndCloseForAnalytics" class="close-nav">
                <img
                  [matTooltip]="'dashboard.killTab' | translate"
                  (click)="onCloseAnalytics()"
                  class="icon-close-nav"
                  src="./assets/img/close-grey.png"
                />
              </td>
            </tr>
          </table>
        </ng-template>
        <app-analytics
          (navigate)="onNavChange($event)"
          *ngIf="
            selectedTab === rights.ANALYTICS ||
            displayTooltipAndCloseForAnalytics
          "
        ></app-analytics>
      </mat-tab>
      <!-- MANAGER -->
      <mat-tab
        label="{{ 'dashboard.manager' | translate }}"
        [aria-label]="rights.MANAGER"
        *ngIf="isAdmin('Admin')"
      >
        <ng-template mat-tab-label>
          <div
            class="manager"
            [ngStyle]="{
              color: managerLoading === false ? '$blue-main' : 'white'
            }"
          >
            {{ "dashboard.manager" | translate }}
          </div>
          <div class="lds-ring" *ngIf="managerLoading">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </ng-template>
        <app-manager
          (navigate)="onNavChange($event)"
          [referenceLoading]="managerLoading"
          *ngIf="selectedTab === rights.MANAGER || managerLoading !== undefined"
        ></app-manager>
      </mat-tab>
      <!-- INCIDENT -->
      <mat-tab
        label="{{ 'dashboard.incident' | translate }}"
        [aria-label]="rights.INCIDENT"
        *ngIf="rights.INCIDENT !== undefined"
      >
        <app-incident
          *ngIf="selectedTab === rights.INCIDENT"
          style="height: 100%"
          (navigate)="onNavChange($event)"
          [incidentFromNavigation]="incident"
        >
        </app-incident>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div class="header-submenu-container">
    <div class="header-submenu">
      <app-lang-manager></app-lang-manager>
      <div class="bubble">
        <div (click)="logoutClicked()" class="login-bubble">
          <p>{{ login }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
