import { Injectable } from "@angular/core";
import { HttpClientWrapper } from "./http-client.wrapper";
import { Observable, Subject } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class ExportService {
  constructor(private httpClientWrapper: HttpClientWrapper<Blob>) {}

  // Méthode pour gérer l'export et le téléchargement automatiquement
  exportToExcel(requestData: any, filter: string): Observable<void> {
    const downloadSubject = new Subject<void>();

    this.httpClientWrapper
      .downloadFile(`${environment.apiBaseUrl}excel/list${filter}`, requestData)
      .subscribe((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "containers-list.xlsx";
        document.body.appendChild(a);
        a.click();

        downloadSubject.next();
        window.URL.revokeObjectURL(url);
      });

    return downloadSubject.asObservable();
  }
}
