import { DeviceContainerStateControllerService } from "../../../services/device-container-state-controller.service";
import { IncidentHistoricalControllerService } from "../../../services/incident-historical-controller.service";
import { IncidentControllerService } from "../../../services/incident-controller.service";
import { DataDecompressorService } from "../../../services/data-decompressor.service";
import { SiteControllerService } from "../../../services/site-controller.service";
import { UserRightsService } from "../../../services/user-rights.service";
import { GeoUtils } from "../../../services/geo-utils";

import { CurrentStateFilterDTO } from "../../../models/current-state-filter";
import { ContainerTypeDTO } from "../../../models/container-type";
import { CurrentStateDTO } from "../../../models/current-state";
import { IncidentDTO } from "../../../models/incident";
import { SiteDTO } from "../../../models/site";

import { LIST } from "../../utils/export-excel/export-excel.component";

import { UPDATE_TYPE } from "../../../stores/data_store/containers-type-store";
import {
  SET_STATUS,
  UPDATE_STATUS
} from "../../../stores/data_store/status-store";

import { animate, style, transition, trigger } from "@angular/animations";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
  Output,
  ViewChild
} from "@angular/core";
import { InfiniteScrollDirective } from "../../../../app/directives/infinite-scroll.directive";
@Component({
  selector: "app-list",
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate("100ms", style({ opacity: 1 }))
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate("600ms", style({ opacity: 0 }))
      ])
    ])
  ],
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"]
})
export class ListComponent implements OnInit, OnDestroy {
  @Output() navigate: EventEmitter<any> = new EventEmitter();

  @Input() infoCurrentState: CurrentStateDTO;
  @Input() filterDevice: string;
  @Input() showInfo: boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(InfiniteScrollDirective) infiniteScroll: InfiniteScrollDirective;

  public showCreateIncident: boolean = false;

  public filterIncidentDevices: string[] = [];
  public filterLastSites: string[] = [];
  public filterIncidents: Number[] = [];
  public filterDevices: string[] = [];
  public filterContainers: any[] = [];
  public filterStatusDays: string[] = [];
  public filterConfidenceRadius: string[] = [];
  public filterSiteCode: string;
  public filterTorn: boolean = false;

  public isEmptyIncident: boolean = true;
  public isEmptyIncidentDevice: boolean = true;

  public sortedCurrentStateList: CurrentStateDTO[] | undefined;
  public listIncidentDevices: CurrentStateDTO[] = [];
  public totalFiteredCurrentStates: number;
  public listIncidents: IncidentDTO[] = undefined;
  public listAddIncident: IncidentDTO[] = undefined;
  public fetchingContainer: boolean;
  public exportType: string = LIST;
  public listSites: SiteDTO[] = [];
  public filterIncident: any = {};
  public loading: boolean = true;
  public pageSize: number = 30;
  public hasSite: boolean;
  public showMap: boolean;

  public exportData: CurrentStateFilterDTO = {
    requestId: 0,
    containerTypes: undefined
  };

  private subscription: Subscription = new Subscription();
  private containerTypes: Array<ContainerTypeDTO>;
  private displayEvaluater: boolean = false;
  private infoPanelVisible: boolean = false;
  private historicDeviceStatePosition: any;
  private initialisation: boolean = false;
  private mustRefreshList: boolean = true;
  private lastSite: SiteDTO | undefined;
  private checkedValue: Array<string>;
  private timeoutId: NodeJS.Timeout;
  private statusList: Array<any>;
  private pageIndex: number = 0;
  private requestId: number = 0;
  private historicTrace: any;
  private allChecked: boolean = false;

  private index: number = 30;
  private readonly STATE_BATCH: number = 60;

  constructor(
    public dialog: MatDialog,
    private siteServices: SiteControllerService,
    private dataDecompressorService: DataDecompressorService,
    private store: Store<any>,
    public _geoUtils: GeoUtils,
    public _deviceContainerStateService: DeviceContainerStateControllerService,
    public _incidentHistoricalControllerService: IncidentHistoricalControllerService,
    public incidentServices: IncidentControllerService,
    public _userRightsService: UserRightsService
  ) {
    this.checkedValue = new Array<string>();
    this.showMap = false;
    this.historicTrace = [];
    this.historicDeviceStatePosition = [];
    this.statusList = [];
    this.initFilters();
  }

  ngOnInit() {
    this.initialisation = true;

    this.initDatas();
    this.initStore();
    this.initExport();

    this.initialisation = false;
    this.mustRefreshList = true;
  }

  ngOnDestroy() {
    if (this.timeoutId) clearTimeout(this.timeoutId);
    this.subscription.unsubscribe();
  }

  private initDatas(): void {
    this.displayEvaluater =
      this._userRightsService.getUserRightLevel("SuperUser");

    this.subscription.add(
      this.siteServices.getSites().subscribe((sites) => {
        this.listSites = sites;
        if (this.filterSiteCode) {
          this.updateList();
        }
      })
    );

    if (!this.listIncidents || !this.listAddIncident) {
      this.subscription.add(
        this.incidentServices.getIncident().subscribe((incidents) => {
          this.listAddIncident = incidents;
          this.incidentServices
            .checkCloseIncident(incidents)
            .subscribe((res) => {
              this.listIncidents = res;
              if (res.length) this.isEmptyIncident = false;
            });
        })
      );
    }
  }

  private initStore(): void {
    this.subscription.add(
      this.store
        .select("ctTypes")
        .subscribe((ctTypesData: ContainerTypeDTO[]) => {
          this.containerTypes = ctTypesData;
          this.exportData = {
            ...this.exportData,
            containerTypes: ctTypesData
          };
          this.mustRefreshList = true;
          this.updateList();
        })
    );

    this.subscription.add(
      this.store.select("statusStore").subscribe((statusData: Array<any>) => {
        this.statusList = statusData;
        this.exportData = {
          ...this.exportData,
          filtersColor: statusData
        };
        this.mustRefreshList = true;
        this.updateList();
      })
    );
  }

  private initExport(): void {
    this.exportData = {
      ...this.exportData,
      containerTypes: !this.filterContainers.length
        ? this.containerTypes
        : this.filterContainers,
      siteId: this.filterSiteCode,
      filtersColor: this.statusList,
      filterDevicesId: !this.filterIncidentDevices?.length
        ? this.filterDevices
        : this.filterIncidentDevices,
      filterLastSites: this.filterLastSites,
      filterIncident: this.filterIncident
    };
  }

  public reloadList(event: any) {
    if (!event) {
      this.infoCurrentState = null;
      this.showInfo = false;
    }
  }

  private initFilters() {
    if (JSON.parse(localStorage.getItem("containersFilter")) !== null) {
      this.filterContainers = JSON.parse(
        localStorage.getItem("containersFilter")
      );
    }
    if (JSON.parse(localStorage.getItem("devicesFilter")) !== null) {
      this.filterDevices = JSON.parse(localStorage.getItem("devicesFilter"));
    }
    if (JSON.parse(localStorage.getItem("lastSitesFilter")) !== null) {
      this.filterLastSites = JSON.parse(
        localStorage.getItem("lastSitesFilter")
      );
    }
    if (JSON.parse(localStorage.getItem("siteFilter")) !== null) {
      this.filterSiteCode = JSON.parse(localStorage.getItem("siteFilter"));
    }
    if (JSON.parse(localStorage.getItem("incidentsFilter")) !== null) {
      this.filterIncidents = JSON.parse(
        localStorage.getItem("incidentsFilter")
      );
      this.setIncidentDevicesId();
    }
    if (JSON.parse(localStorage.getItem("statusDaysFilter")) !== null) {
      this.filterStatusDays = JSON.parse(
        localStorage.getItem("statusDaysFilter")
      );
    }
    if (JSON.parse(localStorage.getItem("confidenceRadiusFilter")) !== null) {
      this.filterConfidenceRadius = JSON.parse(
        localStorage.getItem("confidenceRadiusFilter")
      );
    }
  }

  private updateLocalStorage(key: string, data: any[]): void {
    data?.length
      ? localStorage.setItem(key, JSON.stringify(data))
      : localStorage.removeItem(key);
  }

  public changeContainers(containers) {
    this.resetInfiniteScrollData();
    this.updateLocalStorage("containersFilter", containers);
    this.filterContainers = containers;
    this.exportData = {
      ...this.exportData,
      containerTypes: !containers.length ? this.containerTypes : containers
    };
    this.mustRefreshList = true;
    this.updateList();
  }

  public changeDevices(devices) {
    this.resetInfiniteScrollData();
    this.updateLocalStorage("devicesFilter", devices);
    this.filterDevices = devices;
    if (!this.filterIncidentDevices?.length) {
      this.exportData = {
        ...this.exportData,
        filterDevicesId: devices
      };
      this.mustRefreshList = true;
      this.updateList();
    }
  }

  public changeLastSites(sites) {
    this.resetInfiniteScrollData();
    this.updateLocalStorage("lastSitesFilter", sites);
    this.filterLastSites = sites;
    this.exportData = {
      ...this.exportData,
      filterLastSites: sites
    };
    this.mustRefreshList = true;
    this.updateList();
  }

  private setIncidentDevicesId(): void {
    this.loading = true;
    this.subscription.add(
      this.incidentServices
        .getDevicesIds(this.filterIncidents)
        .subscribe((devicesIds) => {
          this.filterIncidentDevices = devicesIds;
          this.exportData = {
            ...this.exportData,
            filterDevicesId: devicesIds
          };
          this.mustRefreshList = true;
          this.updateList();
        })
    );
  }

  public changeIncidents(incidentIds) {
    this.resetInfiniteScrollData();
    this.updateLocalStorage("incidentsFilter", incidentIds);
    this.filterIncidents = incidentIds;
    if (incidentIds?.length) {
      this.setIncidentDevicesId();
    } else {
      this.filterIncidentDevices = [];
      this.exportData = {
        ...this.exportData,
        filterDevicesId: this.filterDevices
      };
      this.mustRefreshList = true;
      this.updateList();
    }
  }

  public changeStatusDays(statusDays: string[]) {
    this.resetInfiniteScrollData();
    this.updateLocalStorage("statusDaysFilter", statusDays);
    this.filterStatusDays = statusDays;
    this.exportData = {
      ...this.exportData,
      filterStatusDays: statusDays
    };
    this.mustRefreshList = true;
    this.updateList();
  }

  public changeConfidenceRadius(radius) {
    this.resetInfiniteScrollData();
    this.updateLocalStorage("confidenceRadiusFilter", radius);
    this.filterConfidenceRadius = radius;

    this.exportData = {
      ...this.exportData,
      filterConfidenceRadius: radius
    };
    this.mustRefreshList = true;
    this.updateList();
  }

  public changeTorn(torn) {
    this.resetInfiniteScrollData();
    this.filterTorn = torn;

    this.exportData = {
      ...this.exportData,
      filterTorn: torn
    };

    this.mustRefreshList = true;
    this.updateList();
  }

  public closeIncidentCreation(): void {
    this.showCreateIncident = false;
  }

  public changeIncidentDevices(incidentIds) {
    if (!incidentIds) {
      this.showCreateIncident = true;
    }
  }

  public addIncidentList(incident: IncidentDTO) {
    this.listAddIncident = [incident, ...this.listAddIncident];
  }

  public handleDeviceIncident(deviceData): void {
    const index = this.listIncidentDevices.findIndex(
      (device) => device.deviceId === deviceData.device.deviceId
    );

    if (deviceData.checked && index === -1) {
      this.listIncidentDevices = [
        ...this.listIncidentDevices,
        deviceData.device
      ];
    } else if (!deviceData.checked && index >= 0) {
      this.listIncidentDevices.splice(index, 1);
    }
    if (this.listIncidentDevices.length === 0) {
      this.isEmptyIncidentDevice = true;
    } else {
      this.isEmptyIncidentDevice = false;
    }
  }

  public updateFilter(filter: any) {
    this.resetInfiniteScrollData();
    this.store.dispatch({ type: UPDATE_STATUS, payload: filter });
  }

  public siteRequest(event?: string) {
    this.resetInfiniteScrollData();
    if (event) {
      if (!this.filterSiteCode || this.filterSiteCode !== event) {
        this.hasSite = true;
        this.filterSiteCode = event;
        this.exportData = {
          ...this.exportData,
          siteId: event
        };
        this.mustRefreshList = true;
        this.updateList();
      }
    } else {
      this.hasSite = false;
      this.filterSiteCode = undefined;
      this.exportData = {
        ...this.exportData,
        siteId: undefined
      };
      this.mustRefreshList = true;
      this.updateList();
    }
  }

  private updateList(isPageChanged?: boolean) {
    this.requestId++;
    this.loading = true;
    const id: number = this.requestId;
    if (this.timeoutId) clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      if (this.requestId === id && this.mustRefreshList) {
        if (!isPageChanged) {
          this.pageIndex = 0;
        }
        if (this.showCreateIncident) this.showCreateIncident = false;
        this.listIncidentDevices = [];
        this.mustRefreshList = false;
        this.filterSiteCode
          ? this.sendSiteRequest(isPageChanged)
          : this.sendListRequest(isPageChanged);
      }
    }, 1000);
  }

  public loadMore = () => {
    this.filterSiteCode ? this.loadMoreSite() : this.loadMoreList();
  };

  public loadMoreSite() {
    if (!this.loading && this.index < this.totalFiteredCurrentStates) {
      this.loading = true;
      this.subscription.add(
        this._deviceContainerStateService
          .getCurrentStatesBySite({
            requestId: this.requestId,
            containerTypes: !this.filterContainers?.length
              ? this.containerTypes
              : this.filterContainers,
            siteId: this.filterSiteCode,
            limit: this.STATE_BATCH,
            skip: this.index,
            filtersColor: this.statusList,
            filterDevicesId: !this.filterIncidentDevices?.length
              ? this.filterDevices
              : this.filterIncidentDevices,
            filterLastSites: this.filterLastSites,
            filterIncident: this.filterIncident,
            filterConfidenceRadius: this.filterConfidenceRadius,
            filterStatusDays: this.filterStatusDays,
            filterTorn: this.filterTorn
          })
          .subscribe((result) => {
            this.dataDecompressorService
              .unpack(result.partialCurrentStates)
              .then((partialCurrentStates) => {
                if (result.requestId === this.requestId) {
                  this.sortedCurrentStateList.push(...partialCurrentStates);
                  this.index += this.STATE_BATCH;
                  if (
                    this.filterIncidentDevices?.length ||
                    this.filterDevices?.length
                  ) {
                    this.totalFiteredCurrentStates =
                      this.sortedCurrentStateList.length;
                  }
                }
              });
            this.loading = false;
          })
      );
    }
  }

  public loadMoreList() {
    if (!this.loading && this.index < this.totalFiteredCurrentStates) {
      this.loading = true;
      this.subscription.add(
        this._deviceContainerStateService
          .getAllCurrentStatesByContainerType({
            requestId: this.requestId,
            containerTypes: !this.filterContainers?.length
              ? this.containerTypes
              : this.filterContainers,
            limit: this.STATE_BATCH,
            skip: this.index,
            filtersColor: this.statusList,
            filterDevicesId: !this.filterIncidentDevices?.length
              ? this.filterDevices
              : this.filterIncidentDevices,
            filterLastSites: this.filterLastSites,
            filterIncident: this.filterIncident,
            filterStatusDays: this.filterStatusDays,
            filterConfidenceRadius: this.filterConfidenceRadius,
            filterTorn: this.filterTorn
          })
          .subscribe((result) => {
            this.dataDecompressorService
              .unpack(result.partialCurrentStates)
              .then((partialCurrentStates) => {
                if (result.requestId === this.requestId) {
                  this.sortedCurrentStateList.push(...partialCurrentStates);
                  this.index += this.STATE_BATCH;
                  if (
                    this.filterIncidentDevices?.length ||
                    this.filterDevices?.length
                  ) {
                    this.totalFiteredCurrentStates =
                      this.sortedCurrentStateList.length;
                  }
                }
              });
            this.loading = false;
          })
      );
    }
  }

  public sendSiteRequest(_isPageChanged?: boolean) {
    this.subscription.add(
      this._deviceContainerStateService
        .getCurrentStatesBySite({
          requestId: this.requestId,
          containerTypes: !this.filterContainers?.length
            ? this.containerTypes
            : this.filterContainers,
          siteId: this.filterSiteCode,
          limit: this.pageSize,
          skip: this.pageSize * this.pageIndex,
          filtersColor: this.statusList,
          filterDevicesId: !this.filterIncidentDevices?.length
            ? this.filterDevices
            : this.filterIncidentDevices,
          filterLastSites: this.filterLastSites,
          filterIncident: this.filterIncident,
          filterStatusDays: this.filterStatusDays,
          filterConfidenceRadius: this.filterConfidenceRadius,
          filterTorn: this.filterTorn
        })
        .subscribe((result) => {
          this.dataDecompressorService
            .unpack(result.partialCurrentStates)
            .then((partialCurrentStates) => {
              if (result.requestId === this.requestId) {
                this.sortedCurrentStateList = partialCurrentStates;
              }
              if (this.paginator) {
                this.paginator.pageIndex = this.pageIndex;
              }
              this.loading = false;
              this.totalFiteredCurrentStates = result["currentStateLength"];
            });
        })
    );
  }

  public sendListRequest(isPageChanged?: boolean) {
    this.subscription.add(
      this._deviceContainerStateService
        .getAllCurrentStatesByContainerType({
          requestId: this.requestId,
          containerTypes: !this.filterContainers?.length
            ? this.containerTypes
            : this.filterContainers,
          limit: this.pageSize,
          skip: this.pageSize * this.pageIndex,
          filtersColor: this.statusList,
          filterDevicesId: !this.filterIncidentDevices?.length
            ? this.filterDevices
            : this.filterIncidentDevices,
          filterLastSites: this.filterLastSites,
          filterIncident: this.filterIncident,
          filterStatusDays: this.filterStatusDays,
          filterConfidenceRadius: this.filterConfidenceRadius,
          filterTorn: this.filterTorn
        })
        .subscribe((result) => {
          this.dataDecompressorService
            .unpack(result.partialCurrentStates)
            .then((partialCurrentStates) => {
              if (result.requestId === this.requestId) {
                this.sortedCurrentStateList = partialCurrentStates;
                if (
                  this.filterIncidentDevices?.length ||
                  this.filterDevices?.length
                ) {
                  this.totalFiteredCurrentStates =
                    this.sortedCurrentStateList.length;
                }
              }
              if (this.paginator) {
                this.paginator.pageIndex = this.pageIndex;
              }
              this.loading = false;
            });
        })
    );
    if (
      !this.filterDevices.length &&
      !this.filterIncidentDevices.length &&
      !isPageChanged
    ) {
      this.subscription.add(
        this._deviceContainerStateService
          .getStateColorsIndicator({
            requestId: 0,
            containerTypes: !this.filterContainers.length
              ? this.containerTypes
              : this.filterContainers,
            filterLastSites: this.filterLastSites,
            filterStatusDays: this.filterStatusDays,
            filterConfidenceRadius: this.filterConfidenceRadius,
            filterTorn: this.filterTorn
          })
          .subscribe((result) => {
            this.setTotalCurrentStates(result);
          })
      );
    }
  }

  private isAllOrangeActive(): boolean {
    let allActive: boolean = true;

    for (const status of this.statusList) {
      if (!status.show && status.marker.includes("orange")) allActive = false;
    }

    return allActive;
  }

  private setTotalCurrentStates(colors): void {
    let totalFilteredcurrentState: number = 0;
    const allOrangeActive = this.isAllOrangeActive();

    for (const status of this.statusList) {
      if (status.show) {
        if (status.marker.includes("orange")) {
          switch (status.flag) {
            case "flagDetour":
              totalFilteredcurrentState += !allOrangeActive
                ? colors.orange.detour
                : 0;
              break;
            case "flagStatic":
              totalFilteredcurrentState += !allOrangeActive
                ? colors.orange.static
                : 0;
              break;
            case "flagLate":
              totalFilteredcurrentState += !allOrangeActive
                ? colors.orange.late
                : 0;
              break;
          }
        } else {
          totalFilteredcurrentState += colors[status.marker];
        }
      }
    }
    if (allOrangeActive) {
      totalFilteredcurrentState += colors.orange.total;
    }
    this.totalFiteredCurrentStates = totalFilteredcurrentState;
  }

  public refreshList(event, cType: ContainerTypeDTO) {
    this.fetchingContainer = true;
    cType.checked = event.checked;
    this.store.dispatch({ type: UPDATE_TYPE, payload: cType });
  }

  public navChange(event: any): void {
    this.navigate.emit({
      tab: event.tab,
      showDetailMap: event.showDetailMap,
      currentStateMap: event.currentState
    });
  }

  onChangeTab(info: any) {
    this.navigate.emit(info);
  }

  private updateAlertEvaluater(currentState: CurrentStateDTO) {
    this.subscription.add(
      this._incidentHistoricalControllerService
        .updateAlertEvaluater(currentState)
        .subscribe(() => {})
    );
  }

  public filterIncidentChange(event) {
    if (event.deviceId) {
      this.updateAlertEvaluater(event);
      this.filterIncident.openCategory = undefined;
    } else {
      this.filterIncident = event;
      this.mustRefreshList = true;
      this.updateList();
    }
    this.exportData = {
      ...this.exportData,
      filterIncident: this.filterIncident
    };
  }

  public onOpenCategory(event) {
    const filterIncident: any = { ...this.filterIncident };
    filterIncident.openCategory = event;
    this.filterIncident = filterIncident;
    this.exportData = {
      ...this.exportData,
      filterIncident: this.filterIncident
    };
  }

  protected isAdmin(event: string): boolean {
    return this._userRightsService.getUserRightLevel(event);
  }

  public updateAllIncidentDevices(event) {
    this.allChecked = event.checked;
  }

  public showInfoContainer(event: any, currentState: CurrentStateDTO) {
    if (event && event.target && event.target.className === "imageExport") {
      return;
    }
    this.infoCurrentState = currentState;
    this.showInfo = true;
    this.infoPanelVisible = false;
    this.store.dispatch({
      type: SET_STATUS,
      payload: this.statusList.map((filter) => ({
        ...filter,
        showDetail: true
      }))
    });
  }

  public hasHeaderCheckbox(): boolean {
    return !!this.sortedCurrentStateList?.find(
      (currentState) =>
        currentState.statusColor === "ORANGE" ||
        (currentState.statusColor === "RED" &&
          currentState.containerType !== "TORN")
    );
  }

  public resetInfiniteScrollData() {
    this.infiniteScroll?.resetScrollbar();
    this.index = 30;
  }
}
