<div class="state-displayer">
  <div
    *ngIf="currentState === undefined || currentState === null"
    class="skeleton-placeholder"
  >
    <app-state-displayer-skeleton-line
      [type]="getType()"
    ></app-state-displayer-skeleton-line>
  </div>
  <div *ngIf="!hasNotInit" class="state-data">
    <div
      class="incident-checkbox-column state-displayer-column"
      (click)="onHandleDevice($event)"
    >
      <mat-checkbox
        *ngIf="activeAlert && !listDetailDisplay"
        color="primary"
        [checked]="isChecked"
        (click)="$event.stopPropagation()"
        (change)="updateIncidentDevice($event)"
      >
      </mat-checkbox>
    </div>

    <div class="timestamp-column state-displayer-column">
      <div class="value">
        {{ momentTime }}
      </div>
    </div>

    <div class="device-column state-displayer-column">
      <div class="value">
        {{ getDeviceId() }}
      </div>
    </div>

    <div class="container-column state-displayer-column">
      <div class="value">
        {{ getContainerType() }}
      </div>
    </div>

    <div class="status-column state-displayer-column">
      <div class="value">
        <app-state-displayer-status
          [statusDays]="currentState.statusDays"
          [statusDescription]="currentState.statusDescription"
          [statusColor]="currentState.statusColor"
        ></app-state-displayer-status>
      </div>
    </div>

    <div class="radius-column state-displayer-column">
      <div class="value">{{ currentState.confidenceRadiusInMeter }}m</div>
    </div>

    <div
      class="position-column state-displayer-column"
      [class.large-state-column]="listDisplay || listDetailDisplay"
    >
      <div class="value">
        {{ getPosition() }}
      </div>
    </div>

    <div
      class="last-authorized-site-column state-displayer-column"
      [class.large-state-column]="listDisplay || listDetailDisplay"
    >
      <div class="value">
        {{ getLastAuthorizedSite() }}
      </div>
    </div>

    <div
      *ngIf="listDisplay || listDetailDisplay"
      class="time-last-site-column state-displayer-column"
      [class.large-state-column]="mapDisplay"
    >
      <div class="value">
        {{ getTimeLastAuthorizedSite() }}
      </div>
    </div>

    <div class="labels-column state-displayer-column">
      <app-state-displayer-label
        *ngIf="currentState.tornOff"
        labelType="Torn"
      ></app-state-displayer-label>
      <app-state-displayer-label
        *ngIf="currentState.isIncident"
        (changeTab)="onChangeTab($event)"
        labelType="Incident"
        (click)="$event.stopPropagation()"
        [deviceId]="currentState.deviceId"
      ></app-state-displayer-label>
      <p
        *ngIf="!currentState.tornOff && !currentState.isIncident"
        class="value"
      >
        -
      </p>
    </div>
    <ng-container *ngIf="listDisplay || listDetailDisplay">
      <div
        *ngIf="isAdmin('IBMAdmin')"
        class="seq-number-column state-displayer-column"
      >
        <div class="value">
          {{ getSeqNumber() }}
        </div>
      </div>
      <div
        *ngIf="isAdmin('IBMAdmin')"
        class="source-column state-displayer-column"
      >
        <div class="value">
          {{ getSource() }}
        </div>
      </div>
      <div class="battery-column state-displayer-column">
        <svg-icon
          [name]="getBatteryIcon(currentState.batteryLevel)"
          class="battery-level-icon"
        ></svg-icon>
      </div>
      <div
        *ngIf="listDisplay || listDetailDisplay"
        class="maps-column state-displayer-column"
      >
        <app-button
          [rounded]="true"
          iconLeft="map"
          size="small"
          (clicked)="
            redirectToGmap(
              currentState.latitudeComputed,
              currentState.longitudeComputed
            )
          "
        ></app-button>
      </div>
    </ng-container>
  </div>
</div>
