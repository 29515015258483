import { IncidentControllerService } from "../../../services/incident-controller.service";
import { UserControllerService } from "../../../services/user-controller.service";
import { ContainerTypeAuthSiteControllerService } from "../../../services/container-type-auth-site-controller.service";
import { SiteControllerService } from "../../../services/site-controller.service";
import { SimpleDialogComponent } from "../../dialog/simple-dialog.component";
import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  Input
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";

import { ContainerTypeAuthorizedSiteDTO } from "../../../models/container-type-authorized-site";
import { IncidentDTO } from "../../../models/incident";
import { UserDTO } from "../../../models/user";

@Component({
  selector: "app-incident",
  templateUrl: "./incident.component.html",
  styleUrls: ["./incident.component.scss"]
})
export class IncidentComponent implements OnInit, OnDestroy, OnChanges {
  constructor(
    public incidentControllerService: IncidentControllerService,
    public userControllerService: UserControllerService,
    public containerTypeService: ContainerTypeAuthSiteControllerService,
    public SiteService: SiteControllerService,
    public dialog: MatDialog
  ) {}
  @Input() incidentFromNavigation: IncidentDTO | undefined;

  private subscription: Subscription = new Subscription();
  private readonly SELECTOR_STYLE_OPEN = "nav-selector-open";
  private readonly SELECTOR_STYLE_CLOSE = "nav-selector-close";
  private readonly CREATE_INCIDENT = "incident";
  private readonly UPDATE_INCIDENT = "update";
  selectedIncident: Partial<IncidentDTO> = {};
  incidentsList: Array<IncidentDTO>;
  updateModeLabel: string = this.UPDATE_INCIDENT;
  isAddIncident: boolean = false;
  isOnIncidentMainPage: boolean = true;
  newIncidentIsValid: boolean = true;
  updateMode: boolean = false;
  selectedNav: string = "Information";
  selectAll: boolean = false;
  filterUser: any = undefined;
  numberOfAllIncident: number = 0;
  userList: Array<typeof UserDTO.prototype.name>;
  containerTypeList: Array<
    typeof ContainerTypeAuthorizedSiteDTO.prototype.containerTypeId
  >;
  filterIncident: any = undefined;
  loading: boolean = true;

  ngOnInit() {
    this.sendIncidentRequest();
    this.countIncident();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.incidentFromNavigation) {
      this.selectIncident(this.incidentFromNavigation);
    }
  }

  incidentProperties: any[] = [
    {
      label: "creationDate",
      property: "creationDate",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "title",
      property: "title",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "type",
      property: "type",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "lastAuthorizedSite",
      property: "lastAuthorizedSite",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "createdBy",
      property: "createdBy",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "assignedTo",
      property: "assignedTo",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "status",
      property: "status",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "priority",
      property: "priority",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "currentSite",
      property: "currentSite",
      canBeFiltered: true,
      width: 5
    }
  ];

  incidentRequestParameter: any = {
    skip: 0,
    limit: 25,
    filter: {}
  };

  formConfig: any = {
    name: "Information",
    title: "Information",
    type: IncidentDTO,
    inputs: [
      {
        property: "id",
        title: "id",
        type: "number",
        input: true,
        mandatory: false,
        visible: false
      },
      {
        property: "title",
        title: "title",
        type: "string",
        input: true,
        mandatory: true,
        visible: true,
        id: true
      },
      {
        property: "type",
        title: "type",
        type: "string",
        selector: true,
        choices: [
          "Delivery error",
          "Carrier stop",
          "Project request",
          "Temporary flow",
          "Under repair",
          "T&T reference issue",
          "T&T wrong tagging",
          "Other"
        ],
        mandatory: true,
        visible: true
      },
      {
        property: "creationDate",
        title: "creationDate",
        type: "number",
        transform: "date",
        input: true,
        mandatory: true,
        visible: true,
        id: true
      },
      {
        property: "containerType",
        title: "containerType",
        type: "string",
        input: true,
        mandatory: true,
        visible: true,
        id: true
      },
      {
        property: "tantQuantity",
        title: "tantQuantity",
        type: "string",
        transform: "number",
        input: true,
        mandatory: true,
        visible: true,
        id: true
      },
      {
        property: "totalQuantity",
        title: "totalQuantity",
        type: "string",
        transform: "number",
        input: true,
        mandatory: true,
        visible: true
      },
      {
        property: "lastAuthorizedSite",
        title: "lastAuthorizedSite",
        type: "string",
        input: true,
        mandatory: true,
        visible: true,
        id: true
      },
      {
        property: "latitude",
        title: "latitude",
        type: "number",
        transform: "string",
        input: true,
        mandatory: true,
        visible: true,
        id: true
      },
      {
        property: "longitude",
        title: "longitude",
        type: "number",
        transform: "number",
        input: true,
        mandatory: true,
        visible: true,
        id: true
      },
      {
        property: "createdBy",
        title: "createdBy",
        type: "string",
        input: true,
        mandatory: true,
        visible: true,
        id: true
      },
      {
        property: "status",
        title: "status",
        type: "string",
        selector: true,
        choices: [
          "New",
          "In progress",
          "Blocked",
          "Resolved",
          "Cancelled",
          "Rejected"
        ],
        mandatory: true,
        visible: true
      },
      {
        property: "assignedTo",
        title: "assignedTo",
        type: "string",
        search_selector: true,
        choices: "",
        mandatory: true,
        visible: true
      },
      {
        property: "priority",
        title: "priority",
        type: "string",
        selector: true,
        choices: ["High", "Medium", "Low"],
        mandatory: true,
        visible: true
      },
      {
        property: "origin",
        title: "origin",
        type: "string",
        selector: true,
        choices: ["T&T_Analysis", "Central_Request", "GLE_Request"],
        mandatory: true,
        visible: true
      },
      {
        property: "currentSite",
        title: "currentSite",
        type: "string",
        search_selector: true,
        choices: "",
        mandatory: false,
        visible: true
      }
    ]
  };

  navObject: any[] = [
    {
      navName: "Information",
      isOpen: true,
      selectorStyle: this.SELECTOR_STYLE_OPEN
    },
    {
      navName: "Device",
      isOpen: false,
      selectorStyle: this.SELECTOR_STYLE_CLOSE
    },
    {
      navName: "Comment",
      isOpen: false,
      selectorStyle: this.SELECTOR_STYLE_CLOSE
    }
  ];

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  generateIncidentCreation(): IncidentDTO {
    const incident: IncidentDTO = {
      id: this.selectedIncident.id,
      title: this.selectedIncident.title,
      type: this.selectedIncident.type,
      latitude: Number(this.selectedIncident.latitude),
      longitude: Number(this.selectedIncident.longitude),
      currentSite: this.selectedIncident.currentSite,
      creationDate: Number(this.selectedIncident.creationDate),
      status: this.selectedIncident.status,
      createdBy: this.selectedIncident.createdBy,
      lastAuthorizedSite: this.selectedIncident.lastAuthorizedSite,
      assignedTo: this.selectedIncident.assignedTo,
      priority: this.selectedIncident.priority,
      origin: this.selectedIncident.origin,
      containerType: this.selectedIncident.containerType,
      tantQuantity: Number(this.selectedIncident.tantQuantity),
      totalQuantity: Number(this.selectedIncident.totalQuantity)
    };
    return incident;
  }

  sendIncidentRequest() {
    this.subscription.add(
      this.incidentControllerService
        .getIncident(
          this.incidentRequestParameter.skip,
          this.incidentRequestParameter.limit,
          this.filterIncident
        )
        .subscribe((incidents) => {
          this.incidentsList = incidents;
          this.loading = false;
        })
    );
  }

  onNavChange(event: any) {
    for (const nav of this.navObject) {
      nav.isOpen = false;
      nav.selectorStyle = this.SELECTOR_STYLE_CLOSE;
    }
    event.isOpen = true;
    event.selectorStyle = this.SELECTOR_STYLE_OPEN;
    this.selectedNav = event.navName;
  }

  backToList() {
    this.sendIncidentRequest();
    this.isAddIncident = false;
    this.onNavChange(this.navObject[0]);
    this.selectAll = false;
  }

  changeFilterIncident(event) {
    if (event === undefined) {
      this.filterIncident = undefined;
    } else {
      if (event.search !== undefined) {
        const filter: any = { or: [] };
        for (const prop of this.incidentProperties) {
          if (prop.canBeFiltered) {
            const object: any = {};
            object[prop.property] = event.search;
            filter.or.push(object);
          }
        }
        this.filterIncident = filter;
      } else {
        this.filterIncident = { and: [event] };
      }
    }
    this.incidentRequestParameter.skip = 0;
    this.updateIncidentList(true);
  }

  openModifier() {
    this.isAddIncident = true;
    this.isOnIncidentMainPage = false;
  }

  changePageIncident(event) {
    this.incidentRequestParameter.limit = event.limit;
    this.incidentRequestParameter.skip = (event.page - 1) * event.limit;
    this.updateIncidentList(true);
  }

  addIncident() {
    this.selectedIncident = {};
    for (const input of this.formConfig.inputs) {
      input.value = undefined;
      input.valid = undefined;
    }
    this.updateModeLabel = this.CREATE_INCIDENT;
    this.updateMode = false;
    this.openModifier();
  }

  // sendCreateIncident(incident: IncidentDTO) {
  //   this.subscription.add(
  //     this.incidentControllerService.createIncident(incident).subscribe(() => {
  //       this.backToList();
  //       this.updateIncidentList(true);
  //     })
  //   );
  // }

  countIncident() {
    this.subscription.add(
      this.incidentControllerService
        .getCount(this.filterIncident)
        .subscribe((res) => {
          this.numberOfAllIncident = res.count;
        })
    );
  }

  updateIncidentList(update: boolean) {
    if (update === true) {
      this.sendIncidentRequest();
    }
  }

  saveChanges() {
    if (this.updateMode) {
      this.sendUpdateIncident(this.generateIncidentCreation());
    }
    //  else {
    //   this.sendCreateIncident(this.generateIncidentCreation());
    // }
  }

  selectIncident(event: IncidentDTO) {
    let filterUser = {
      or: [
        { rightId: "User" },
        { rightId: "SuperUser" },
        { rightId: "Admin" },
        { rightId: "User/MobileUser" },
        { rightId: "User/MobileSuperUser" },
        { rightId: "SuperUser/MobileUser" },
        { rightId: "SuperUser/SuperMobileUser" },
        { rightId: "IBMAdmin" }
      ]
    };
    this.updateMode = true;
    this.selectedIncident = { ...event };
    for (const input of this.formConfig.inputs) {
      if (input.property === "assignedTo") {
        this.subscription.add(
          this.userControllerService
            .getUserNames(filterUser)
            .subscribe((users) => {
              input.choices = users
                .map(({ name }) => {
                  return name;
                })
                .sort();
              input.filteredOptions = input.choices;
            })
        );
      } else if (input.property === "currentSite") {
        this.SiteService.getSites().subscribe((sites) => {
          input.choices = sites
            .map(({ code, label }) => ({ label, code }))
            .sort();
          input.filteredOptions = input.choices;
        });
      }
      input.value = this.selectedIncident[input.property];
      input.valid = true;
    }
    this.updateModeLabel = this.UPDATE_INCIDENT;
    this.openModifier();
  }

  changeSelectedIncident(event: IncidentDTO) {
    this.selectedIncident = { ...event };
  }

  sendUpdateIncident(incident: IncidentDTO) {
    if (!incident.currentSite) {
      delete incident.currentSite;
    }

    if (
      typeof incident.currentSite === "object" &&
      incident.currentSite?.code
    ) {
      incident.currentSite = incident.currentSite.code;
    }

    this.subscription.add(
      this.incidentControllerService.patchIncident(incident).subscribe(() => {
        this.backToList();
        this.updateIncidentList(true);
      })
    );
  }

  sendDeleteIncident() {
    let incident = this.selectedIncident.id;
    this.subscription.add(
      this.incidentControllerService.deleteIncident(incident).subscribe(() => {
        this.backToList();
        this.updateIncidentList(true);
      })
    );
  }

  deleteIncident() {
    const dialogRef = this.dialog.open(SimpleDialogComponent, {
      data: {
        title: `Delete ${this.selectedIncident.title}`,
        text: `Do you want to delete this incident permanently?`,
        yes: "Yes",
        no: "No"
      }
    });

    this.subscription.add(
      dialogRef.afterClosed().subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.sendDeleteIncident();
        }
      })
    );
  }

  isFormValid(event: boolean) {
    this.newIncidentIsValid = event;
  }
}
