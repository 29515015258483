import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { IncidentControllerService } from "../../../services/incident-controller.service";
import { userAccess } from "../../../services/user-rights.service";
import { IncidentDTO } from "../../../models/incident";

@Component({
  selector: "app-state-displayer-label",
  templateUrl: "./state-displayer-label.component.html",
  styleUrls: ["./state-displayer-label.component.scss"]
})
export class StateDisplayerLabelComponent implements OnInit {
  @Input() labelType: "Incident" | "Torn";
  @Input() deviceId;
  @Output() changeTab: EventEmitter<any> = new EventEmitter<any>();

  constructor(private incidentService: IncidentControllerService) {}

  ngOnInit(): void {}

  navigateToIncident(incident: IncidentDTO) {
    this.changeTab.emit({
      tab: userAccess.INCIDENT,
      incident: incident
    });
  }

  public getLastIncident() {
    this.incidentService
      .getLastIncidentByDeviceId(this.deviceId)
      .subscribe((res) => {
        this.navigateToIncident(res);
      });
  }
}
