import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClientWrapper } from "./http-client.wrapper";
import {
  IncidentDTO,
  IncidentAssociatedDeviceDTO,
  IncidentCommentDTO
} from "../models/incident";
import { CountDTO } from "../models/historical-state";
import { environment } from "../../environments/environment";

@Injectable()
export class IncidentControllerService {
  constructor(
    public httpClientWrapper: HttpClientWrapper<IncidentDTO>,
    public httpClientWrapperDevice: HttpClientWrapper<IncidentAssociatedDeviceDTO>,
    public httpClientWrapperComment: HttpClientWrapper<IncidentCommentDTO>,
    public httpClientwrapperDeviceIds: HttpClientWrapper<string>,
    public httpClientCount: HttpClientWrapper<CountDTO>
  ) {}

  getIncident(
    skip?: number,
    limit?: number,
    filterInput?: any
  ): Observable<IncidentDTO[]> {
    let filter: any = {};
    if (skip) filter.skip = skip;
    if (limit) filter.limit = limit;
    if (filterInput) filter.where = filterInput;
    filter.order = "id DESC";

    return this.httpClientWrapper.get(
      `${environment.apiBaseUrl}incidents?filter=${JSON.stringify(filter)}`
    );
  }

  getIncidentComment(
    incidentId: typeof IncidentAssociatedDeviceDTO.prototype.incidentId,
    skip?: number,
    limit?: number,
    filterInput?: any
  ): Observable<IncidentCommentDTO[]> {
    let filter: any = {
      where: {
        incidentId: incidentId
      }
    };

    if (skip) filter.skip = skip;
    if (limit) filter.limit = limit;
    if (filterInput) filter.where = filterInput;
    filter.order = "id DESC";

    return this.httpClientWrapperComment.get(
      `${
        environment.apiBaseUrl
      }incident/comments/${incidentId}?filter=${JSON.stringify(filter)}`
    );
  }

  createIncidentComment(CommentCreation: Partial<IncidentCommentDTO>) {
    return this.httpClientWrapperComment.postUnique(
      environment.apiBaseUrl + "incident/comment",
      CommentCreation
    );
  }

  createIncident(IncidentCreation: IncidentDTO) {
    return this.httpClientWrapper.postUnique(
      environment.apiBaseUrl + "incident",
      IncidentCreation
    );
  }

  deleteIncident(incident: typeof IncidentDTO.prototype.id) {
    return this.httpClientWrapper.delete(
      environment.apiBaseUrl + "incident/" + incident
    );
  }

  getCount(filterInput?: any): Observable<CountDTO> {
    let filterFormated: string = "?where=";
    let filter: any = {};
    if (filterInput) {
      filter = filterInput;
    }
    filterFormated = filterFormated + JSON.stringify(filter);

    return this.httpClientCount.getUnique(
      environment.apiBaseUrl + "incident/count" + filterFormated
    );
  }

  getCountDevices(incidentId: typeof IncidentDTO.prototype.id) {
    let filterFormated: string = "?where=";

    const where = {
      incidentId: incidentId
    };

    filterFormated = filterFormated + JSON.stringify(where);

    return this.httpClientCount.getUnique(
      environment.apiBaseUrl + "incident/devices/count" + filterFormated
    );
  }

  getCountComment(incidentId: typeof IncidentDTO.prototype.id) {
    let filterFormated: string = "?where=";

    const where = {
      incidentId: incidentId
    };

    filterFormated = filterFormated + JSON.stringify(where);

    return this.httpClientCount.getUnique(
      environment.apiBaseUrl + "incident/comments/count" + filterFormated
    );
  }

  patchIncident(incidentCreation: IncidentDTO) {
    return this.httpClientWrapper.put(
      environment.apiBaseUrl + "incidents/" + incidentCreation.id,
      incidentCreation
    );
  }

  getDevices(
    incidentId: typeof IncidentAssociatedDeviceDTO.prototype.incidentId,
    skip?: number,
    limit?: number,
    filterInput?: any
  ): Observable<IncidentAssociatedDeviceDTO[]> {
    let filter: any = {
      where: {
        incidentId: incidentId
      }
    };

    if (skip) filter.skip = skip;
    if (limit) filter.limit = limit;
    if (filterInput) filter.where = filterInput;
    filter.order = "id DESC";

    return this.httpClientWrapperDevice.get(
      `${
        environment.apiBaseUrl
      }incident/deviceIds/${incidentId}?filter=${JSON.stringify(filter)}`
    );
  }

  getDevicesIds(incidentsIds: Number[]): Observable<string[]> {
    let filter: string = "?filter=";
    const where: any = {
      where: {
        isOpened: true,
        incidentId: {
          inq: incidentsIds
        }
      }
    };

    filter = filter + JSON.stringify(where);
    return this.httpClientwrapperDeviceIds.get(
      `${environment.apiBaseUrl}incident/allDevicesIds${filter}`
    );
  }

  getFirstIncident(deviceId: string): Observable<IncidentDTO> {
    return this.httpClientWrapper.getUnique(
      `${environment.apiBaseUrl}incidents/firstIncident/${deviceId}`
    );
  }

  postSelectDevices(devices: Array<Partial<IncidentAssociatedDeviceDTO>>) {
    return this.httpClientWrapperDevice.post(
      environment.apiBaseUrl + "incident/devices",
      devices
    );
  }

  deleteDevices(devices: Array<IncidentAssociatedDeviceDTO>) {
    return this.httpClientWrapperDevice.deleteAll(
      environment.apiBaseUrl + "incident/devices",
      devices
    );
  }

  checkCloseIncident(
    incidents: Array<IncidentDTO>
  ): Observable<Array<IncidentDTO>> {
    return this.httpClientWrapper.post(
      environment.apiBaseUrl + "incident/check-close-incident",
      incidents
    );
  }

  getLastIncidentByDeviceId(deviceId: string): Observable<IncidentDTO> {
    return this.httpClientWrapper.getUnique(
      environment.apiBaseUrl + `incident/lastIncident/${deviceId}`
    );
  }
}
