import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output
} from "@angular/core";
import { Subscription } from "rxjs";
import { CurrentStateDTO } from "../../../models/current-state";
import { DeviceContainerStateControllerService } from "../../../services/device-container-state-controller.service";

interface DeviceData {
  checked: boolean;
  device: CurrentStateDTO;
}
@Component({
  selector: "app-state-popup",
  templateUrl: "./state-popup.component.html",
  styleUrls: ["./state-popup.component.scss"]
})
export class StatePopupComponent implements OnChanges, OnDestroy {
  @Output() handleDevice: EventEmitter<DeviceData> = new EventEmitter();
  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickedIncident: EventEmitter<CurrentStateDTO> = new EventEmitter();

  @Input() currentStates: CurrentStateDTO[];
  @Input() clusterDeviceIds: string[];
  @Input() isSelectionOn: boolean;

  private subscription: Subscription = new Subscription();
  private isUpdatingIncident: boolean = false;
  private isLoadmore: boolean = false;
  private index: number = 30;
  private allChecked: boolean = false;

  private readonly STATE_BATCH: number = 60;

  constructor(
    private deviceContainerStateControllerService: DeviceContainerStateControllerService
  ) {}

  public ngOnChanges(changes) {
    if (changes.currentStates) {
      this.isUpdatingIncident = true;
      this.deepClone(changes.currentStates);
    } else if (changes.isSelectionOn === false) this.isUpdatingIncident = false;
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private deepClone(currentStates: CurrentStateDTO[]) {
    this.currentStates = [...currentStates];
    for (let i = 0; i++; i < currentStates.length) {
      this.currentStates[i].incident = currentStates[i].incident;
    }
  }

  public loadMore = () => {
    if (!this.isUpdatingIncident && !this.isLoadmore) {
      let newIndex: number = this.index;
      if (!(newIndex >= this.clusterDeviceIds.length)) {
        newIndex += this.STATE_BATCH;
        if (newIndex > this.clusterDeviceIds.length) {
          newIndex = this.clusterDeviceIds.length;
        }
        this.isLoadmore = true;
        this.subscription.add(
          this.deviceContainerStateControllerService
            .getClusterCurrentStates(
              this.clusterDeviceIds.slice(this.index, newIndex)
            )
            .subscribe((result) => {
              this.isLoadmore = false;
              for (const currentState of result)
                this.currentStates.push({ ...currentState });
            })
        );
        this.index = newIndex;
      }
    }
  };

  public deviceIdClicked(currentState: CurrentStateDTO) {
    this.clicked.emit(currentState);
  }

  public onHandleDevice(event) {
    this.handleDevice.emit(event);
  }

  public isLoading(): boolean {
    return this.currentStates == null;
  }

  public updateAllIncidentDevices(event) {
    this.allChecked = event.checked;
  }

  public hasHeaderCheckbox(): boolean {
    return !!this.currentStates?.find(
      (currentState) =>
        currentState.statusColor === "ORANGE" ||
        (currentState.statusColor === "RED" &&
          currentState.containerType !== "TORN")
    );
  }

  public onChangeTab(info: any) {
    this.clickedIncident.emit(info);
  }
}
