import { Component, EventEmitter, Input, Output, OnInit } from "@angular/core";

type ButtonColor = "grey" | "blue";
type ButtonType = "outlined" | "filled";
type ButtonSize = "small" | "large";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"]
})
export class ButtonComponent implements OnInit {
  @Output() clicked: EventEmitter<void> = new EventEmitter();

  @Input() color: ButtonColor = "blue";
  @Input() type: ButtonType = "outlined";
  @Input() labelPath?: string = "";
  @Input() iconRight?: string;
  /* If the button is icon-only, use iconLeft */
  @Input() iconLeft?: string;
  @Input() rounded: boolean = false;
  @Input() underlined: boolean = false;
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;
  @Input() size: ButtonSize = "large";

  public buttonClass: string = "custom-button";

  constructor() {}

  public ngOnInit(): void {
    const roundedClass = this.rounded ? "rounded" : "";
    const underlinedClass = this.underlined ? "underlined" : "";
    const iconOnlyClass =
      this.labelPath || this.iconRight ? "" : `icon-only-${this.size}`;
    this.buttonClass = `custom-button button-${this.color}-${this.type} ${roundedClass} ${underlinedClass} ${iconOnlyClass} ${this.size}`;
  }

  public onClicked(event: Event): void {
    if (this.loading) return;

    event.stopPropagation();
    this.clicked.emit();
  }
}
