import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import * as moment from "moment/moment";
import { Subscription } from "rxjs";
import { ContainerTypeDTO } from "../../../models/container-type";
import { CurrentStateDTO } from "../../../models/current-state";
import { CurrentStateFilterDTO } from "../../../models/current-state-filter";
import { HistoricalStateDTO } from "../../../models/historical-state";
import { DataDecompressorService } from "../../../services/data-decompressor.service";
import { DeviceContainerStateControllerService } from "../../../services/device-container-state-controller.service";
import { XlsxExporter } from "../../../services/xlsx-exporter";
import { ExportService } from "../../../services/export.service";

export const GRAPH_STORAGE = "button-graph-storage";
export const GRAPH_TRANSIT = "button-graph-transit";
export const LIST = "button-list";
export const DETAIL = "button-list-detail";
export const MAP = "button-map-detail";
export const STATE = "icon-state";

type DataGraph = {
  name: string;
  y: string[];
};

@Component({
  selector: "app-export-excel",
  templateUrl: "./export-excel.component.html",
  styleUrls: ["./export-excel.component.scss"]
})
export class ExportExcelComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  private containerLabel: string = "";
  private deviceId: string;
  public loading: boolean = false;

  @Input() type: string;
  @Input() data: any;
  @Input() totalListCurrentStates: number = 0; // temporary Input for total list export
  @Input() containerType: string = "";
  @Input() disabled: boolean = false;

  constructor(
    private store: Store<any>,
    private _xlsxExporter: XlsxExporter,
    private _deviceContainerStateService: DeviceContainerStateControllerService,
    private dataDecompressorService: DataDecompressorService,
    private exportService: ExportService
  ) {}

  ngOnInit(): void {
    if (this.containerType) {
      this.subscription.add(
        this.store
          .select("ctTypes")
          .subscribe((ctTypesData: ContainerTypeDTO[]) => {
            const ctData = ctTypesData.find(
              (ct) => ct.code === this.containerType
            );
            this.containerLabel = ctData ? ctData.label : "";
          })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  isClient(code: string): boolean {
    return code.length === 2;
  }

  // remove empty string then convert string in number
  convertToNumber(values: string[]): number[] {
    return values.reduce(
      (prev, curr) => (!curr ? prev : [...prev, Number(curr)]),
      []
    );
  }

  // return largest
  getMax(y: string[]): number {
    const values: number[] = this.convertToNumber(y);

    return Math.round(Math.max(...values) * 10) / 10;
  }

  //return smallest
  getMin(y: string[]): number {
    const values: number[] = this.convertToNumber(y);

    return Math.round(Math.min(...values) * 10) / 10;
  }

  // return average
  getMean(y: string[]): number {
    const values: number[] = this.convertToNumber(y);

    return (
      Math.round((values.reduce((a, b) => a + b, 0) / values.length) * 10) / 10
    );
  }

  // return median
  getMedian(y: string[]): number {
    let result: number;
    const values: number[] = this.convertToNumber(y).sort((a, b) => a - b);
    const half: number = Math.floor(values.length / 2);

    result =
      values.length % 2 ? values[half] : (values[half - 1] + values[half]) / 2;

    return Math.round(result * 10) / 10;
  }

  // check if the data is undefined or empty, then return the result of requested calculation if not, undefined if yes
  calcul(cb: Function, values: string[]) {
    return !values || values.length <= 0 ? undefined : cb.bind(this)(values);
  }

  // return excel row for Storage Time Graph
  formatStorage(data: any): any {
    return {
      CU: data.cu,
      FNR: data.cofor,
      Emb: this.containerType,
      Designation: this.containerLabel,
      "mean(CU)": this.calcul(this.getMean, data.cuValues),
      "median(CU)": this.calcul(this.getMedian, data.cuValues),
      "min(CU)": this.calcul(this.getMin, data.cuValues),
      "max(CU)": this.calcul(this.getMax, data.cuValues),
      "mean(FNR)": this.calcul(this.getMean, data.coforValues),
      "median(FNR)": this.calcul(this.getMedian, data.coforValues),
      "min(FNR)": this.calcul(this.getMin, data.coforValues),
      "max(FNR)": this.calcul(this.getMax, data.coforValues)
    };
  }

  storageGraphExport(data: DataGraph[]) {
    let exportData: any[] = [];
    const cuList: any[] = data.filter((value) => this.isClient(value.name));
    const coforList: any[] = data.filter((value) => !this.isClient(value.name));

    if (cuList.length > 0) {
      exportData = cuList.reduce((prev, cu) => {
        if (coforList.length > 0) {
          const data: any[] = coforList.map((cofor) => {
            return this.formatStorage({
              cu: cu.name,
              cofor: cofor.name,
              cuValues: cu["y"],
              coforValues: cofor["y"]
            });
          });
          return [...prev, ...data];
        } else {
          return [
            ...prev,
            this.formatStorage({ cu: cu.name, cuValues: cu["y"] })
          ];
        }
      }, []);
    } else {
      exportData = coforList.map(({ name, y }) =>
        this.formatStorage({ cofor: name, coforValues: y })
      );
    }

    if (exportData.length > 0) {
      this._xlsxExporter.exportAsExcelFile(
        { "Storage time": exportData },
        "storage-time"
      );
    }
    this.loading = false;
  }

  transitGraphExport(data: DataGraph[]) {
    const exportData = data.reduce((prev, { name, y }) => {
      const sites: string[] = name.split("/");
      const i: number = prev.findIndex(
        (value) =>
          sites.includes(value["site A"]) && sites.includes(value["site B"])
      );

      if (i === -1) {
        return [
          ...prev,
          {
            "site A": sites[0],
            "site B": sites[1],
            Emb: this.containerType,
            Designation: this.containerLabel,
            "mean(A)": this.calcul(this.getMean, y),
            "median(A)": this.calcul(this.getMedian, y),
            "min(A)": this.calcul(this.getMin, y),
            "max(A)": this.calcul(this.getMax, y),
            "mean(B)": undefined,
            "median(B)": undefined,
            "min(B)": undefined,
            "max(B)": undefined
          }
        ];
      } else {
        prev[i] = {
          ...prev[i],
          "mean(B)": this.calcul(this.getMean, y),
          "median(B)": this.calcul(this.getMedian, y),
          "min(B)": this.calcul(this.getMin, y),
          "max(B)": this.calcul(this.getMax, y)
        };
        return prev;
      }
    }, []);

    if (exportData.length > 0) {
      this._xlsxExporter.exportAsExcelFile(
        { "Transit time": exportData },
        "transit-time"
      );
    }
    this.loading = false;
  }

  private UnpackListData(wrappedData: string): void {
    this.dataDecompressorService.unpack(wrappedData).then((result) => {
      const exportData = result.map((currentState) => {
        const position = {
          Date: moment
            .unix(currentState.receivedMessageTime)
            .format("DD/MM/YYYY HH:mm"),
          "Container Type": currentState.containerType,
          "Device ID": currentState.deviceId,
          SiteCofor: currentState.currentSiteCofor
            ? currentState.currentSiteCofor
            : "",
          Site: currentState.currentSite ? currentState.currentSite : "",
          "Position (lat,lng)":
            currentState.latitudeComputed +
            "," +
            currentState.longitudeComputed,
          "Position source": currentState.locationSource,
          Status: currentState.statusDescription,
          Days: currentState.statusDays,
          "Last Authorized site COFOR": currentState.lastAuthorizedSiteCofor
            ? currentState.lastAuthorizedSiteCofor
            : "",
          "Last authorized site": currentState.lastAuthorizedSite
            ? currentState.lastAuthorizedSite
            : "",
          "Assembly user": currentState.assemblyUser,
          "Registration date": currentState.registrationDate
            ? moment
                .unix(currentState.registrationDate)
                .format("DD/MM/YYYY HH:mm")
            : "",
          "Pre-associated date": currentState.preAssociatedDate
            ? moment
                .unix(currentState.preAssociatedDate)
                .format("DD/MM/YYYY HH:mm")
            : "",
          "Assembly validation": currentState.assemblyEndDate
            ? moment
                .unix(currentState.assemblyEndDate)
                .format("DD/MM/YYYY HH:mm")
            : "",
          Associated: currentState.associatedDate
            ? moment
                .unix(currentState.associatedDate)
                .format("DD/MM/YYYY HH:mm")
            : "",
          Dissociated: currentState.dissociatedDate
            ? moment
                .unix(currentState.dissociatedDate)
                .format("DD/MM/YYYY HH:mm")
            : "",
          Label: currentState.incident
            ? "Alerte non pertinente"
            : currentState.incident === false
              ? "Alerte à traiter"
              : ""
        };

        return position;
      });

      if (exportData.length > 0) {
        this._xlsxExporter.exportAsExcelFile(
          { Containers: exportData },
          "containers-list"
        );
      }
      this.loading = false;
    });
  }

  listExport(data: CurrentStateFilterDTO) {
    if (data?.siteId) {
      this.subscription.add(
        this._deviceContainerStateService
          .getCurrentStatesBySite(data, true)
          .subscribe((result) => {
            this.UnpackListData(result.partialCurrentStates);
          })
      );
    } else if (data?.filterDevicesId?.length) {
      this.subscription.add(
        this._deviceContainerStateService
          .getAllCurrentStatesByContainerType(data)
          .subscribe((result) => {
            this.UnpackListData(result.partialCurrentStates);
          })
      );
    } else {
      const ctypes: string[] = this.data.containerTypes.map((c) => c.code);
      const filter: string =
        this._deviceContainerStateService.createListFilter(data);

      this.exportService
        .exportToExcel(
          {
            containerTypes: ctypes,
            totalCurrentStates: this.totalListCurrentStates
          },
          filter
        )
        .subscribe(() => {
          this.loading = false;
        });
    }
  }

  listDetailExport(data: CurrentStateFilterDTO) {
    this.subscription.add(
      this._deviceContainerStateService
        .getHistoricalStateByDeviceContainer(data)
        .subscribe((result) => {
          if (result.length > 0) {
            this.deviceId = result[0].deviceId;
            this.containerType = result[0].containerType;
          }

          const exportData = result.map(
            (historicalState: HistoricalStateDTO) => {
              const r = <any>{
                Date: moment
                  .unix(historicalState.receivedMessageTime)
                  .format("DD/MM/YYYY HH:mm"),
                "Container Type": historicalState.containerType,
                "Device ID": historicalState.deviceId,
                Site: historicalState.currentSite
                  ? historicalState.currentSite
                  : "",
                "Position source": historicalState.locationSource,
                Correction: historicalState.currentSite
                  ? "Matching Site"
                  : "none",
                Status: historicalState.statusDescription,
                "Position (lat,lng)":
                  historicalState.latitudeComputed +
                  "," +
                  historicalState.longitudeComputed,
                "Precision (m)": Math.round(
                  historicalState.confidenceRadiusInMeter
                )
              };

              return {
                // Reorder
                Date: r["Date"] || "",
                "Container Type": r["Container Type"] || "",
                "Device ID": r["Device ID"] || "",
                Site: r["Site"] || "",
                "Position (lat,lng)": r["Position (lat,lng)"] || "",
                "Precision (m)": r["Precision (m)"] || "",
                Status: r["Status"] || "",
                "Position source": r["Position source"] || ""
              };
            }
          );

          if (exportData.length > 0) {
            this._xlsxExporter.exportAsExcelFile(
              { Statuses: exportData },
              `container-${this.containerType}-${this.deviceId}`
            );
          }
          this.loading = false;
        })
    );
  }

  mapDetailExport(data: HistoricalStateDTO[]) {
    const exportData = data.map((historicalState: HistoricalStateDTO) => {
      const r = <any>{
        Date: moment
          .unix(historicalState.receivedMessageTime)
          .format("DD/MM/YYYY HH:mm"),
        "Container Type": historicalState.containerType,
        "Device ID": historicalState.deviceId,
        Site: historicalState.currentSite ? historicalState.currentSite : "",
        "Position source": historicalState.locationSource,
        Correction: historicalState.currentSite ? "Matching Site" : "none",
        Status: historicalState.statusDescription,
        "Position (lat,lng)":
          historicalState.latitudeComputed +
          "," +
          historicalState.longitudeComputed,
        "Precision (m)": Math.round(historicalState.confidenceRadiusInMeter)
      };

      return {
        // Reorder
        Date: r["Date"] || "",
        "Container Type": r["Container Type"] || "",
        "Device ID": r["Device ID"] || "",
        Site: r["Site"] || "",
        "Position (lat,lng)": r["Position (lat,lng)"] || "",
        "Precision (m)": r["Precision (m)"] || "",
        Status: r["Status"] || "",
        "Position source": r["Position source"] || "",
        Correction: r["Correction"] || ""
      };
    });
    this._xlsxExporter.exportAsExcelFile(
      { Statuses: exportData, Attributes: [] },
      `container-${this.containerType}-${this.deviceId}`
    );
    this.loading = false;
  }

  public async stateExport(currentState: CurrentStateDTO) {
    let historicalStatesList: Array<HistoricalStateDTO>;

    await this._deviceContainerStateService
      .getHistoricalStateByDeviceContainer({
        requestId: 0,
        containerTypes: [],
        filterDeviceId: currentState.deviceId
      })
      .toPromise()
      .then((res) => (historicalStatesList = res))
      .catch((err) => err);

    const exportData = historicalStatesList.map(
      (historicalState: HistoricalStateDTO) => {
        const r = <any>{
          Date: moment
            .unix(historicalState.receivedMessageTime)
            .format("DD/MM/YYYY HH:mm"),
          "Container Type": currentState.containerType,
          "Device ID": currentState.deviceId,
          Site: historicalState.currentSite ? historicalState.currentSite : "",
          "Position source": historicalState.locationSource,
          Correction: historicalState.currentSite ? "Matching Site" : "none",
          Status: historicalState.statusDescription,
          "Position (lat,lng)":
            historicalState.latitudeComputed +
            "," +
            historicalState.longitudeComputed,
          "Precision (m)": Math.round(historicalState.confidenceRadiusInMeter)
        };

        return {
          // Reorder
          Date: r["Date"] || "",
          "Container Type": r["Container Type"] || "",
          "Device ID": r["Device ID"] || "",
          Site: r["Site"] || "",
          "Position (lat,lng)": r["Position (lat,lng)"] || "",
          "Position source": r["Position source"] || "",
          Status: r["Status"] || ""
        };
      }
    );

    this._xlsxExporter.exportAsExcelFile(
      { Statuses: exportData },
      "container-" + currentState.containerType + "-" + currentState.deviceId
    );
    this.loading = false;
  }

  exportXLSX(): void {
    this.loading = true;
    switch (this.type) {
      case GRAPH_STORAGE:
        this.storageGraphExport(this.data);
        break;
      case GRAPH_TRANSIT:
        this.transitGraphExport(this.data);
        break;
      case LIST:
        this.listExport(this.data);
        break;
      case DETAIL:
        this.listDetailExport(this.data);
        break;
      case MAP:
        this.mapDetailExport(this.data);
        break;
      case STATE:
        this.stateExport(this.data);
        break;
      default:
        return;
    }
  }
}
